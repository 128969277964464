import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'

import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'

import {useAuth} from '../core/Auth'

import HeaderImage from './HeaderImage'
import FooterImage from './FooterImage'
import CaneBotImage from './CaneBotImage'
const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
})

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        console.log('login')
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Invalid email or password. Please check your credentials.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  return (
    <div
      className='container-fluid d-flex flex-column justify-content-between'
      style={{height: '100vh', maxWidth: '100%', padding: '0'}}
    >
      <HeaderImage />
      <div className='row' style={{width: '100%'}}>
        <div className='col-sm-6' style={{margin: 'auto'}}>
          <CaneBotImage />
        </div>
        <div className='col-sm-6' style={{margin: 'auto'}}>
          <div
            className='card card-header border-0 mt-2'
            style={{justifyContent: 'center', alignItems: 'center'}}
          >
            <form
              className='form'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
              style={{width: '60%'}}
            >
              <div className='text-center mb-4'>
                <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
              </div>
              {formik.status && <div className='alert alert-danger'>{formik.status}</div>}
              <div className='fv-row mb-4'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                  // onChange={(e) => setEmail(e.target.value)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder='Email'
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {'is-valid': formik.touched.email && !formik.errors.email}
                  )}
                  style={{padding: '20px'}}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-4'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <input
                  //  onChange={(e) => setPassword(e.target.value)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  placeholder='Password'
                  type='password'
                  autoComplete='off'
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.password && formik.errors.password},
                    {'is-valid': formik.touched.password && !formik.errors.password}
                  )}
                  name='password'
                  style={{padding: '20px'}}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-grid mb-4'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary bg-primary'
                  style={{
                    backgroundColor: formik.isSubmitting || !formik.isValid ? '#cccccc' : '#007bff',
                    borderColor: formik.isSubmitting || !formik.isValid ? '#999999' : '#007bff',
                    color: formik.isSubmitting || !formik.isValid ? '#666666' : '#ffffff',
                  }}
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Continue</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
              Not a Member yet?{' '}
              <Link to='/auth/registration' className='link-primary'>
                Sign up
              </Link>
            </div> */}
            </form>
          </div>
        </div>
      </div>
      <FooterImage />
    </div>
  )
}
