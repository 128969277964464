/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Search} from '../../../_metronic/partials'
import Modal from 'react-bootstrap/Modal'

type Props = {
  className: string
}
// Your static data stored in an array
const membersData = [
  {
    id: 1,
    Id: '001',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Inactive',
  },
  {
    id: 2,
    Id: '002',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Active',
  },
  {
    id: 3,
    Id: '003',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Active',
  },
  {
    id: 4,
    Id: '004',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Active',
  },
  {
    id: 5,
    Id: '005',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Active',
  },
  {
    id: 6,
    Id: '006',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Active',
  },
  {
    id: 7,
    Id: '007',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Active',
  },
  {
    id: 8,
    Id: '008',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Inactive',
  },
  {
    id: 9,
    Id: '009',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Inactive',
  },
  {
    id: 10,
    Id: '010',

    Phone: '9876543210',
    JoinedDate: '12/01/2023',
    status: 'Inactive',
  },
]

// Inside your Machines component
const Users: React.FC<Props> = ({className}) => {
  const [showModal, setShowModal] = useState(false)

  const addUserHandler = () => {
    setShowModal(true) // Function to show the modal
  }

  const closeModal = () => {
    setShowModal(false) // Function to close the modal
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>{/* <Search /> */}</div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm mx-2'
            style={{background: '#493184'}}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
            onClick={addUserHandler}
          >
            <KTIcon iconName='plus' className='fs-3 text-white' />
            <span className='text-white'>Add Users</span>
          </a>
          <a
            href='#'
            style={{background: '#493184'}}
            className='btn btn-sm mx-2'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='filter' className='fs-3 text-white' />
            <span className='text-white'>Filter</span>
          </a>
          <a
            href='#'
            className='btn btn-sm mx-2'
            style={{background: '#493184'}}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='arrows' className='fs-3 text-white' />
            <span className='text-white'>Export</span>
          </a>
        </div>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-80px'>ID</th>
                <th className='min-w-200px'>Users</th>
                <th className='min-w-120px'>Phone</th>
                <th className='min-w-120px'>Joined Date</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-140px'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {membersData.map((member) => (
                <tr key={member.id}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value='1'
                      />
                    </div>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.Id}
                    </span>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          Ana Simmons
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          HTML, JS, ReactJS
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.Phone}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.JoinedDate}
                    </span>
                  </td>

                  <td className=''>
                    {member.status === 'Active' && (
                      <span className='badge badge-light-success'>Active</span>
                    )}
                    {member.status === 'Inactive' && (
                      <span className='badge badge-light-danger'>Inactive</span>
                    )}
                  </td>

                  <td>
                    <div className='d-flex '>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered>
        <h2 style={{textAlign: 'center', width: '100%'}} className='mt-4'>
          Profile
        </h2>

        <Modal.Body>
          {/* Content of your modal goes here */}
          {/* For example, your vertically centered modal */}
          <div className='modal-dialog'>
            <h3>Avatar</h3>
            <div className='fv-row mb-8 text-center'>
              {' '}
              {/* Use text-center class here */}
              <img
                src='https://mdbcdn.b-cdn.net/img/new/avatars/1.webp'
                className='rounded-circle shadow-4 mx-auto'
                style={{width: '50px'}}
                alt='Avatar'
              />
            </div>
            <div className='fv-row mb-8'>
              <h3>General Email</h3>
              <input
                placeholder='max@kt.com'
                className='form-control bg-transparent mt-3'
                type='email'
                name='email'
              />
            </div>
            <div className='fv-row mb-8'>
              <h3>Full Name</h3>
              <div className='d-flex justify-content-between'>
                <div>
                  <input
                    placeholder='Max'
                    className='form-control bg-transparent mt-3'
                    type='text'
                    name='fname'
                  />
                </div>
                <div>
                  <input
                    placeholder='smith'
                    className='form-control bg-transparent mt-3'
                    type='text'
                    name='lname'
                  />
                </div>
              </div>
            </div>
            <div className='fv-row mb-8'>
              <h3>Contact Number</h3>
              <input
                placeholder='91+'
                className='form-control bg-transparent mt-3'
                type='name'
                name='number'
              />
            </div>
          </div>
        </Modal.Body>
        <div className='mb-6 text-center'>
          <button type='button' className='btn btn-secondary btn-sm mx-2' onClick={closeModal}>
            Close
          </button>
          <button
            type='button'
            className='btn btn-sm mx-2 text-white'
            style={{background: '#493184'}}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  )
}

export {Users}
