import React from 'react'
import image2 from '../../../../assests/Footer.png'
const FooterImage = () => {
  return (
    <div className=''>
    <img src={image2} style={{width:"100%"}}></img>
  </div>
  )
}

export default FooterImage