/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Search} from '../../../_metronic/partials'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
interface GlassData {
  id: number
  // machineId: string;
  orderId: string
  flavours: string
  status: string
}
type Props = {
  className: string
}

// Inside your Machines component
const Glass: React.FC<Props> = ({className}) => {
  const [glasses, setGlasses] = useState<GlassData[]>([])
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [totalPages, setTotalPages] = useState(1);
  const API_URL = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchGlasses = async () => {
      try {
        const response = await axios.get(`${API_URL}/getglass`,{
          params: {
            page: page,
            limit: limit,
            // search: query
          },
        })
        const {data,totalCount}=response.data
        console.log('Fetched data:', response.data)
        setGlasses(response.data.data)
        setTotalPages(Math.ceil(totalCount /limit));
      } catch (error) {
        console.log(error)
      }
    }
    fetchGlasses()
  }, [page])
  const handlePreviousPage = () => {
    setPage((page) => Math.max(page - 1, 1))
  }

  const handleNextPage = () => {
    setPage((page) => page + 1)
  }
  
  
  const pageRange = 2; 
  
  // Calculate the start and end page numbers to display based on the current page
  const startPage = Math.max(1, page - pageRange);
  const endPage = Math.min(totalPages, page + pageRange);

  // Create an array of page numbers to display
  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);


  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>{/* <Search /> */}</div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            style={{background: '#493184'}}
            className='btn btn-sm mx-2'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='filter' className='fs-3 text-white' />
            <span className='text-white'>Filter</span>
          </a>
          <a
            href='#'
            className='btn btn-sm mx-2'
            style={{background: '#493184'}}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='arrows' className='fs-3 text-white' />
            <span className='text-white'>Export</span>
          </a>
        </div>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>ID</th>
                <th className='min-w-150px'>Order No</th>
                <th className='min-w-200px'>Flavours</th>
                <th className='min-w-150px'>Status</th>

                {/* <th className='min-w-150px'>ACTIONS</th> */}
              </tr>
            </thead>
            <tbody>
              {glasses.map((member) => (
                <tr key={member.id}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value='1'
                      />
                    </div>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.id}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.orderId}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.flavours}
                    </span>
                  </td>

                  <td className=''>
                    {member.status === 'success' && (
                      <span className='badge badge-light-success'>Success</span>
                    )}
                    {member.status === 'error' && (
                      <span className='badge badge-light-danger'>Error</span>
                    )}
                  </td>

                  {/* <td>
                    <div className='d-flex '>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </a>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav aria-label='Page navigation example'>
      <ul className='pagination'>
        <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
          <a className='page-link' href='#' onClick={handlePreviousPage}>
            Previous
          </a>
        </li>
        {/* Loop through the displayed page numbers */}
        {pageNumbers.map((pageNumber) => (
          <li className={`page-item ${page === pageNumber ? 'active' : ''}`} key={pageNumber}>
            <a className='page-link' onClick={() => setPage(pageNumber)}>
              {pageNumber}
            </a>
          </li>
        ))}
        <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
          <a className='page-link' onClick={handleNextPage}>
            Next
          </a>
        </li>
      </ul>
    </nav>
      </div>
    </div>
  )
}

export {Glass}
