import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Discount } from './Discount'

const DiscountWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Machines</PageTitle>
  <Discount className=''/>
    </>
  )
}

export default DiscountWrapper
