import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {Orders} from "./Orders"
// import { UsersListPagination } from '../../modules/apps/user-management/users-list/components/pagination/UsersListPagination';

const OrderPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Orders</PageTitle>
      <div className=''>
        <Orders/>
      </div>
    </>
  )
}

export default OrderPageWrapper
