import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import './Discount.css'
import axios from 'axios'

type Props = {
  className: string
}

type QueryParams = {
  [key: string]: string | undefined
}

interface Machine {
  location: string
  division: string
  chasisID: string
  Discount: number
  machineId:string
}

const Discount: React.FC<Props> = ({className}) => {
  const [machineData, setMachineData] = useState<Machine[]>([])
  const [showDiscount, setShowDiscount] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [location, setLocation] = useState('')
  const [division, setDivision] = useState('')
  const [chasisID, setChasisId] = useState('')
  const urlPath = useLocation()
  const [queryParams, setQueryParams] = useState<QueryParams>({})
  const [discount, setDiscount] = useState<string>('')
  const [updateData, setUpdateData] = useState(true)
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(urlPath.search)
    const parsedParams: QueryParams = {}
    params.forEach((value, key) => {
      parsedParams[key] = value
    })
    setQueryParams(parsedParams)
  }, [urlPath.search])

  let token = ''
  const tokenItem = localStorage.getItem('kt-auth-react-v')
  if (tokenItem) {
    const {api_token} = JSON.parse(tokenItem)
    if (typeof api_token === 'string') {
      token = api_token
    } else {
      // Handle case where api_token is null or not a string
    }
  } else {
    // Handle case where tokenItem is null
  }

  const API_URL = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchDataAndSetup = async () => {
      try {
        const response = await axios.get(`${API_URL}/machine_Id/${queryParams?.machineId}`)
        setMachineData(response.data)
        console.log('response.data444444444444', response.data)
        if (response.data.length > 0) {
          const initialMachine = response.data[0]
          setLocation(initialMachine.location)
          setDivision(initialMachine.division)
          setChasisId(initialMachine.chasisID)
          setDiscount((initialMachine.Discount ?? '').toString())

          // Handle zero discount correctly
          setShowDiscount(
            editMode &&
              initialMachine.Discount !== undefined &&
              initialMachine.Discount !== null &&
              initialMachine.Discount > 0
          )
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (queryParams.machineId) {
      fetchDataAndSetup()
    }
  }, [queryParams.machineId, updateData, editMode, token])
  const saveDiscountToAPI = async () => {
    try {
      // If discount is being turned off, set it to 0 in the database
      const updatedDiscount = showDiscount ? parseFloat(discount) : 0;

  
      // Add validation to ensure discount is greater than 0
      if (updatedDiscount >= 0) {
        await axios.put(
          `${API_URL}/editMachine/${queryParams?.machineId}`,
          {
            Discount: updatedDiscount,
            location: location,
            division: division,
            chasisID: chasisID,
            machineId: queryParams.machineId,
          }
        );
        console.log('Discount saved successfully!'); 
        setUpdateData(!updateData);
        setEditMode(false);
      } else {
        const errorMessage = 'Discount must be greater than 0.';
        console.error(errorMessage);
        setErrorMessage(errorMessage);
      }
    } catch (error) {
      console.error('Error while saving discount:', error);
    }
  };
  
  

  return (
    <div>
      <div className={`card ${className}`} style={{margin: 'auto'}}>
       
        <div>
          {machineData.map((machine, index) => (
            <div className='card-body py-2' key={index}>
               <div className='card-body py-2'>
          <h3 className='d-flex justify-content-around mt-8'>
            Machine Id: {machine.machineId}
          </h3>
        </div>
              {/* Location */}
              <div className={`fv-row mb-8`}>
                <label htmlFor='location'>Location:</label>
                {editMode ? (
                  <input
                    id='location'
                    placeholder='Enter Location'
                    className={`form-control bg-transparent mt-3`}
                    type='text'
                    name='location'
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                ) : (
                  <span>{machine.location}</span>
                )}
              </div>
              {/* Division */}
              <div className={`fv-row mb-8`}>
                <label htmlFor='zone'>Zone:</label>
                {editMode ? (
                  <input
                    id='zone'
                    placeholder='Enter Zone'
                    className={`form-control bg-transparent mt-3`}
                    type='text'
                    name='division'
                    value={division}
                    onChange={(e) => setDivision(e.target.value)}
                  />
                ) : (
                  <span>{machine.division}</span>
                )}
              </div>
              {/* Chasis ID */}
              <div className={`fv-row mb-8`}>
                <label htmlFor='chasisId'>Chasis Id:</label>
                {editMode ? (
                  <input
                    id='chasisId'
                    placeholder='Enter Chasis Id'
                    className={`form-control bg-transparent mt-3`}
                    type='text'
                    name='chasisId'
                    value={chasisID}
                    onChange={(e) => setChasisId(e.target.value)}
                  />
                ) : (
                  <span>{machine.chasisID}</span>
                )}
              </div>
              {/* Discount */}
              <div className='fv-row mb-8'>
                {editMode && <label htmlFor='discount'>Discount:</label>}
                {!editMode && <label htmlFor='discount'>Discount: {machine.Discount}</label>}

                {editMode && (
                  <label className='switch'>
                    <input
                      type='checkbox'
                      checked={showDiscount}
                      onChange={() => setShowDiscount(!showDiscount)}
                    />
                    <span className='slider round'></span>
                  </label>
                )}

                {editMode && showDiscount && (
                  <input
                    id='discount'
                    placeholder='Enter Discount'
                    className={`form-control bg-transparent mt-3`}
                    type='number'
                    name='discount'
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                  
                )}
                {errorMessage && (
                    <span className="text-danger">
                      {errorMessage}
                    </span>
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='mt-5 d-flex justify-content-end'>
        {!editMode && (
          <button type='button' className='btn btn-danger' onClick={() => setEditMode(true)}>
            Edit
          </button>
        )}
        {editMode && (
          <>
            <button
              type='button'
              className='btn btn-danger me-4'
              onClick={() => setEditMode(false)}
            >
              Cancel
            </button>
            <button type='button' className='btn btn-primary' onClick={saveDiscountToAPI}>
              Save
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export {Discount}
