import React, {useEffect, useState} from 'react'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts'

interface LineChart1Props {
  chartData: OrderData[]
}
interface OrderData {
  day: string
  hour: string
  tq: number
  av: number
  Total_Orders: number
  Average_Value: number
}

const LineChart1: React.FC<LineChart1Props> = ({chartData}) => {
  const totalMax = Math.max(...chartData.map((data) => data.Total_Orders))

  const AvgMax = Math.max(...chartData.map((data) => data.Average_Value))

  const maxOfBoth = Math.max(totalMax, AvgMax)

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <div className='container'>
          <h4 className='mt-4 mb-3'>Number of Orders report (Today)</h4>
          <div className='d-flex'>
            <div className='d-flex mb-6 mx-2'>
              <div
                className='rounded-square rounded-2 '
                style={{
                  background: '#FFC700',
                  width: '20px',
                  height: '20px',
                  marginRight: '5px',
                }}
              ></div>
              <span>Total Orders</span>
            </div>
            <div className='d-flex mb-6 mx-2'>
              <div
                className='rounded-square rounded-2 '
                style={{
                  background: '#50CD89',
                  width: '20px',
                  height: '20px',
                  marginRight: '5px',
                }}
              ></div>
              <span>Average Value</span>
            </div>
          </div>
          <LineChart
            width={450}
            height={400}
            data={chartData}
            margin={{
              top: 5,
              right: 50,
              left: 0,
              bottom: 14,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='hour'
              label={{
                value: 'Day',
                position: 'insideBottom',
                offset: -8,
                style: {fontSize: '16px', fontWeight: 'bold'},
              }}
            />
            <Tooltip />
            <YAxis type='number' domain={[0, maxOfBoth + 20]} />
            <Line
              type='monotone'
              dataKey='Total_Orders'
              stroke='#FFC700'
              fill='rgba(136, 132, 216, 0.6)'
              isAnimationActive={false}
              activeDot={{r: 8}}
            />
            <Line
              type='monotone'
              dataKey='Average_Value'
              stroke='#50CD89'
              fill='rgba(136, 132, 216, 0.6)'
              isAnimationActive={false}
              activeDot={{r: 8}}
            />
          </LineChart>
        </div>
      </div>
    </div>
  )
}

export default LineChart1
