import React, {useState, useEffect} from 'react'
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip} from 'recharts'

interface OrderData {
  hour: string
  total: string
  Failed: number
  Success: number
}

interface BarChartProps {
  chartData: OrderData[]
}

const BarChart1: React.FC<BarChartProps> = ({chartData}) => {
  const barRadius: [number, number, number, number] = [8, 8, 0, 0]

  const totalMax = Math.max(...chartData.map((data) => parseInt(data.total, 10)))

  return (
    <>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h4>Number Of Glasses Report (Day)</h4>
          <BarChart
            width={600}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='hour'
              label={{
                value: 'Day',
                position: 'insideBottom',
                offset: -15,
                style: {fontSize: '20px', fontWeight: 'bold'},
              }}
            />

            <YAxis type='number' domain={[0, totalMax + 20]} />
            <Tooltip position={{ x: 200, y: 50 }} />
            <Legend layout='horizontal' align='center' verticalAlign='top' />
            <Bar dataKey='total' fill='#FFC700' className='rounded-10' radius={barRadius} />
            <Bar dataKey='Success' fill='#50CD89' className='rounded-4' radius={barRadius} />
            <Bar dataKey='Failed' fill='#F1416C' className='rounded-8' radius={barRadius} />
          </BarChart>
        </div>
      </div>
    </>
  )
}

export default BarChart1
