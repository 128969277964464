import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Machines } from './Machine'

const MachineWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Machines</PageTitle>
    <Machines className=''/>
    </>
  )
}

export default MachineWrapper
