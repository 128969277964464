import React, {useState, useEffect} from 'react'
import ReactApexChart from 'react-apexcharts'
import axios from 'axios'

interface FlavorsData {
  flavor: string
  quantity: number
}

const ApexChart: React.FC = () => {
  const [chartData, setChartData] = useState<{
    series: number[]
    options: {
      chart: {type: 'donut'}
      legend: {show: boolean}
      colors?: string[]
      labels?: string[]
      tooltip?: {y: {formatter: (val: number) => string}}
    }
  }>({
    series: [],
    options: {
      chart: {type: 'donut'},
      legend: {show: false},
    },
  })

  const [tableData, setTableData] = useState<FlavorsData[]>([])
  const API_URL = process.env.REACT_APP_API_URL

  const colorsArray = [
    '#FFC700',
    '#50CD89',
    '#F1416C',
    '#7239EA',
    '#3E97FF',
    '#C7B3F3',
    // Add more colors if needed
  ]

  const getColorForFlavor = (index: number): string =>
    colorsArray[index % colorsArray.length] || 'black'

  useEffect(() => {
    const fetchFlavorsData = async () => {
      try {
        const flavorTitlesResponse = await axios.get(`${API_URL}/get/flavour`)
        const fetchedTitles = flavorTitlesResponse.data.map((item: any) => item.title)

        const updatedTableData: FlavorsData[] = []

        for (const title of fetchedTitles) {
          try {
            const flavorQuantityResponse = await axios.get(
              `${API_URL}/flavorquantity?flavour=${encodeURIComponent(title)}`
            )
            const responseData = flavorQuantityResponse.data[0] // Assuming there's only one object in the array
            const quantity = responseData ? parseInt(responseData[title] || 0) : 0

            updatedTableData.push({flavor: title, quantity})
          } catch (error) {
            console.error(`Error fetching quantity for flavor "${title}":`, error)
            updatedTableData.push({flavor: title, quantity: 0}) // In case of error, set quantity to 0
          }
        }

        // Update chart data

        setChartData({
          series: updatedTableData.map((data) => data.quantity),
          options: {
            chart: {type: 'donut'},
            legend: {show: false},
            labels: updatedTableData.map((data) => data.flavor),
            colors: updatedTableData.map((_, index) => getColorForFlavor(index)),
            tooltip: {y: {formatter: (val: number) => `${val} units`}},
          },
        })

        // Update table data
        setTableData(updatedTableData)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchFlavorsData()
  }, [])

  return (
    <div className='card'>
      <div id='chart'>
        <h2 className='mt-4 mb-1 ms-8'>Today's Flavors Status</h2>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type='donut'
          style={{width: '70%', marginLeft: '10%'}}
        />
      </div>
      <div className='table-container table-responsive ms-8 me-2' style={{marginTop: '20px'}}>
        <table
          className='table  table-row-gray-300 align-middle gs-0 gy-1'
          style={{borderCollapse: 'separate', borderSpacing: '0 6px', width: '500px'}}
        >
          <thead className=''>
            <tr className='fw-bold text-muted'>
              <th scope='col'></th>
              <th>Flavor</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((flavorData, index) => (
              <tr key={index}>
                <td>
                  <div
                    className='rounded-square rounded-2'
                    style={{
                      backgroundColor: getColorForFlavor(index),
                      width: '20px',
                      height: '20px',
                      marginRight: '5px',
                    }}
                  ></div>
                </td>
                <td>{flavorData.flavor}</td>
                <td className=''>{flavorData.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ApexChart
