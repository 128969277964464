import {FC} from 'react'

import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import BarChart from '../../../_metronic/partials/widgets/dashboardContent/BarChart/BarChart'
import SecondRow from '../../../_metronic/partials/widgets/dashboardContent/SecondRow/SecondRow'
import LineBar from '../../../_metronic/partials/widgets/dashboardContent/LineBar/LineBar'
import FourthRow from '../../../_metronic/partials/widgets/dashboardContent/Map/FourthRow'

const DashboardPage: FC = () => (
  <div className='overflow-hidden mt-2'>
    <BarChart />
    
    <LineBar />
    <FourthRow />
    <SecondRow />
  </div>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
