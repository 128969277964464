import React from 'react'
import CreateDoughnutData from './CreateDoughnutData'
import Performing from './Performing'

function SecondRow() {
  return (
    <div>
      <div className='container mt-8 '>
        <div className='row'>
          <div className='col-md-6 mb-3 '>
            <CreateDoughnutData />
          </div>
          <div className='col-md-6 mb-3 '>
            {/* <Performing /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondRow
