import React, {useEffect, useState} from 'react'
import LineChart1 from './LineChart1'
import LineChart2 from './LineChart2'
import axios from 'axios'

interface OrderData {
  day: string
  hour: string
  tq: number
  av: number
  Total_Orders: number
  Average_Value: number
}
interface OrderData2 {
  day: string
  hour: string
  tq: number
  av: number
  Total_Orders: number
  Average_Value: number
}

function LineBar() {
  const [chartData, setChartData] = useState<OrderData[]>([])
  const [chartData2, setChartData2] = useState<OrderData2[]>([])
  const API_URL = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/get/report`)
        const {reportData, reportData2} = response.data

        // Format the data into the structure that recharts can understand
        const formattedData = reportData2.map((data: OrderData2) => {
          return {
            hour: data.hour,
            Total_Orders: data.tq,
            Average_Value: data.av,
          }
        })

        const formattedDailyData = reportData.map((data: OrderData) => ({
          day: data.day,
          Total_Orders: data.tq,
          Average_Value: data.av,
        }))

        setChartData(formattedData)
        setChartData2(formattedDailyData)
      } catch (err) {
        console.log(err)
      }
    }

    fetchData()
  }, [])

  return (
    <div className='container mt-4'>
      <div className='row'>
        <div className='col-md-6 mb-3'>
          <LineChart1 chartData={chartData} />
        </div>
        <div className='col-md-6 mb-3'>
          <LineChart2 chartData2={chartData2} />
        </div>
      </div>
    </div>
  )
}

export default LineBar
