import React, {useEffect, useState} from 'react'
import BarChart1 from './BarChart1'
import BarChart2 from './BarChart2'
import axios from 'axios'
interface OrderData {
  hour: string

  total: string
  Failed: number
  Success: number
}
interface OrderData2 {
  day: string
  total: string
  Failed: number
  Success: number
}

function BarChart() {
  const [chartData, setChartData] = useState<OrderData[]>([])
  const [chartData2, setChartData2] = useState<OrderData2[]>([])
  const API_URL = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/get/report/individual`)
        const {reportData, reportData2} = response.data

        // Format the data into the structure that recharts can understand
        const formattedData = reportData2.map((data: OrderData) => ({
          hour: data.hour,
          total: data.total,
          Failed: data.Failed,
          Success: data.Success,
        }))

        const formattedDailyData = reportData.map((data: OrderData2) => ({
          day: data.day,
          total: data.total,
          Failed: data.Failed,
          Success: data.Success,
        }))

        setChartData(formattedData)
        setChartData2(formattedDailyData)
      } catch (err) {
        console.log(err)
      }
    }

    fetchData()
  }, [])
  return (
    <>
      <div className='container mt-8 '>
        <div className='row'>
          <div className='col-md-6 mb-3 '>
            <BarChart1 chartData={chartData} />
          </div>
          <div className='col-md-6 mb-3 '>
            <BarChart2 chartData={chartData2} />
          </div>
        </div>
      </div>
    </>
  )
}

export default BarChart
// import React, { useEffect, useState } from 'react';
// import BarChart1 from './BarChart1';
// import BarChart2 from './BarChart2';

// interface OrderData {
//   hour: string;
//   total: string;
//   Failed: number;
//   Success: number;
// }

// interface OrderData2 {
//   day: string;
//   total: string;
//   Failed: number;
//   Success: number;
// }

// const initialChartData: OrderData[] = [
//   {
//     hour: "10:00 AM",
//     total: "7",
//     Failed: 0,
//     Success: 5
//   },
//   {
//     hour: "11:00 AM",
//     total: "5",
//     Failed: 0,
//     Success: 2
//   },
//   {
//     hour: "12:00 PM",
//     total: "2",
//     Failed: 0,
//     Success: 2
//   },
//   {
//     hour: "01:00 PM",
//     total: "20",
//     Failed: 0,
//     Success: 20
//   }
// ];

// const initialChartData2: OrderData2[] = [
//   {
//     day: "2024-06-11T00:00:00.000Z",
//     total: "132",
//     Failed: 0,
//     Success: 94
//   },
//   {
//     day: "2024-06-12T00:00:00.000Z",
//     total: "140",
//     Failed: 0,
//     Success: 94
//   },
//   {
//     day: "2024-06-13T00:00:00.000Z",
//     total: "130",
//     Failed: 0,
//     Success: 111
//   },
//   {
//     day: "2024-06-14T00:00:00.000Z",
//     total: "71",
//     Failed: 0,
//     Success: 52
//   },
//   {
//     day: "2024-06-17T00:00:00.000Z",
//     total: "157",
//     Failed: 0,
//     Success: 127
//   },
//   {
//     day: "2024-06-18T00:00:00.000Z",
//     total: "34",
//     Failed: 0,
//     Success: 29
//   }
// ];

// function BarChart() {
//   const [chartData, setChartData] = useState<OrderData[]>(initialChartData);
//   const [chartData2, setChartData2] = useState<OrderData2[]>(initialChartData2);

//   return (
//     <>
//       <div className='container mt-8'>
//         <div className='row'>
//           <div className='col-md-6 mb-3'>
//             <BarChart1 chartData={chartData} />
//           </div>
//           <div className='col-md-6 mb-3'>
//             <BarChart2 chartData={chartData2} />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default BarChart;
