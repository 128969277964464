/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Search} from '../../../_metronic/partials'
import Modal from 'react-bootstrap/Modal'
import {format} from 'date-fns'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {Icon} from '@iconify/react'
import {useNavigate} from 'react-router-dom'

type Props = {
  className: string
}

interface MachineData {
  id: number
  machineId: string
  // machineId: number
  division: string
  location: string
  machineStatus: string
  mode: string
  lastRefillTime: string
  chasisID: string
}

// Inside your Machines component
const Machines: React.FC<Props> = ({className}) => {
  const [machines, setMachines] = useState<MachineData[]>([])
  const [showModal, setShowModal] = useState(false)
  const [showDelete, setshowDelete] = useState(false)
  const [showDiscount, setShowDiscount] = useState(false)

  const [machineId, setMachineId] = useState<string>('')
  const [division, setDivision] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [mode, setMode] = useState<string>('')

  const [boardNumber, setboardNumber] = useState<string>('')
  const [chasisId, setChasisId] = useState<string>('')
  const [dateOfManufauctring, setdateOfManufauctring] = useState<string>('')
  const [machineData, setmachineData] = useState<number>()
  const [query, setEnterSearch] = useState<string>('')
  const [sortColumn, setSortColumn] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [totalPages, setTotalPages] = useState(1)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const navigate = useNavigate()

  const API_URL = process.env.REACT_APP_API_URL
  const handleSort = (column: any) => {
    if (sortColumn === column) {
      // If the column is already sorted, toggle the direction
      setSortDirection((prevDir) => (prevDir === 'asc' ? 'desc' : 'asc'))
    } else {
      // If sorting a new column, set the column and default direction to ascending
      setSortColumn(column)
      setSortDirection('asc')
    }
  }
  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const response = await axios.get(`${API_URL}/getmachine`, {
          params: {
            page: page,
            limit: limit,
            search: query,
            sortBy: sortColumn,
            sortDir: sortDirection,
          },
        })
        const {data, totalCount} = response.data

        setMachines(response.data.data)
        
        setTotalPages(Math.ceil(totalCount / limit))
      } catch (error) {
        console.log(error)
      }
    }
    fetchMachines()
  }, [showModal, page, sortColumn, sortDirection])

  console.log('showmodel', showModal)

  const OpenModelHandler = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false) // Function to close the modal
  }
  const addMachinesHandler = async () => {
    try {
      const response = await axios.post(`${API_URL}/create/machine`, {
        boardNumber: boardNumber,
        chasisID: chasisId,
        dateOfManufauctring: dateOfManufauctring,
        machineId: machineId, // Added machineId field
      })
      console.log(response)
      console.log('Machine added successfully')
      setShowModal(false)
      setMachineId('')
      setChasisId('')
      setboardNumber('')
      setdateOfManufauctring('')

    } catch (error: any) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message === 'machine already Exists'
      ) {
        // Display an error message to the user
        setErrorMessage('Machine id already exists')
      } else {
        // Handle other types of errors
        console.log(error)
      }
    }
  }

  const deleteMachine = (val: number) => {
    setshowDelete(true)
    setmachineData(val)
  }
  const closeDelete = () => {
    setshowDelete(false)
  }
  const deleteMachineHandler = async (machineId: number) => {
    try {
      const response = await axios.put(`${API_URL}/deactivatemachine/${machineId}`)

      // Update the status of the machine in the state
      setMachines((prevMachines) =>
        prevMachines.map((machine) =>
          machine.id === machineId ? {...machine, machineStatus: 'Inactive'} : machine
        )
      )

      // Close the delete confirmation modal
      setshowDelete(false)

      console.log('Machine status changed successfully:', response.data)
    } catch (error) {
      console.error('Error deactivating machine:', error)
    }
  }

  const DiscountMachine = (machine: MachineData) => {
    console.log('machineDataId', machine.machineId)
  }



  const handlePreviousPage = () => {
    setPage((page) => Math.max(page - 1, 1))
  }

  const handleNextPage = () => {
    setPage((page) => page + 1)
  }
  const filteredMachines = machines?.filter((order) => String(order.id).includes(query))
  const pageRange = 2

  const startPage = Math.max(1, page - pageRange)
  const endPage = Math.min(totalPages, page + pageRange)

  // Create an array of page numbers to display
  const pageNumbers = Array.from({length: endPage - startPage + 1}, (_, index) => startPage + index)

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>
          {/* <Search setEnterSearch={setEnterSearch} /> */}
        </div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm mx-2'
            style={{background: '#493184'}}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
            onClick={OpenModelHandler}
          >
            <KTIcon iconName='plus' className='fs-3 text-white' />
            <span className='text-white'>Add Machines</span>
          </a>
        </div>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>

                <th className='min-w-120px'>
                  <div className='d-flex align-items-center'>
                    <span className='me-1'>Machine ID</span>
                    {sortColumn === 'id' && (
                      <Icon
                        icon={sortDirection === 'asc' ? 'carbon:arrow-up' : 'carbon:arrow-down'}
                      />
                    )}
                  </div>
                </th>

                <th className='min-w-120px'>Chasis ID</th>
                <th className='min-w-120px' onClick={() => handleSort('division')}>
                  <div className='d-flex align-items-center'>
                    {sortColumn === 'division' && (
                      <Icon
                        icon={sortDirection === 'asc' ? 'carbon:arrow-up' : 'carbon:arrow-down'}
                      />
                    )}
                    <span className='me-1'>Division</span>
                  </div>
                </th>
                <th className='min-w-120px' onClick={() => handleSort('location')}>
                  <div className='d-flex align-items-center'>
                    {sortColumn === 'location' && (
                      <Icon
                        icon={sortDirection === 'asc' ? 'carbon:arrow-up' : 'carbon:arrow-down'}
                      />
                    )}
                    <span className='me-1'> Location</span>
                  </div>
                </th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-120px'>Mode</th>
                <th className='min-w-120px'>Last refill time</th>
                <th className='min-w-120px'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredMachines.map((member) => (
                <tr key={member.id}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value='1'
                      />
                    </div>
                  </td>

                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.machineId}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.chasisID}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.division}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.location}
                    </span>
                  </td>

                  <td className=''>
                    {member.machineStatus === 'Active' && (
                      <span className='badge badge-light-success'>Active</span>
                    )}
                    {member.machineStatus === 'Inactive' && (
                      <span className='badge badge-light-danger'>Inactive</span>
                    )}
                  </td>
                  <td className=''>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.mode}
                    </span>
                  </td>
                  <td className=''>
                    <span
                      className='text-muted fw-semibold text-muted d-block'
                      style={{fontSize: '12px'}}
                    >
                      {member.lastRefillTime
                        ? format(new Date(member.lastRefillTime), 'MMM dd, yyyy hh:mm:ss a')
                        : ''}
                    </span>
                  </td>

                  <td>
                    <div className='d-flex '>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        onClick={() => DiscountMachine(member)}
                      >
                        <Link
                          to={`/discount?machineId=${member?.machineId}`}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTIcon iconName='eye' className='fs-3' />
                        </Link>
                      </a>

                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        onClick={() => deleteMachine(member.id)}
                      >
                        <Icon icon='grommet-icons:power-shutdown' />
                      </a>
                    </div>
                  </td>
                  <Modal show={showDelete} onHide={closeDelete} centered>
                    <Modal.Body>
                      <div className='d-flex flex-column text-center'>
                        <div>
                          <span>Are you sure you want to deactive Machine?</span>
                        </div>
                      </div>
                    </Modal.Body>
                    <div className='mb-6 text-center'>
                      <button
                        type='button'
                        className='btn btn-secondary btn-sm mx-2'
                        onClick={closeDelete}
                      >
                        Close
                      </button>
                      <button
                        type='button'
                        className='btn btn-sm mx-2 text-white btn-danger'
                        onClick={() => deleteMachineHandler(machineData ?? -1)}
                      >
                        Deactive
                      </button>
                    </div>
                  </Modal>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered>
        <h2 style={{textAlign: 'center', width: '100%'}} className='mt-4'>
          Add Machines
        </h2>

        <Modal.Body>
          <div className=''>
            <div className='fv-row mb-8'>
              <input
                placeholder='Machine ID' // New field for Machine ID
                className='form-control bg-transparent mt-3'
                type='text'
                name='machineId'
                value={machineId}
                onChange={(e) => setMachineId((e.target.value))}
              />
              <span className='text-danger'>{errorMessage}</span>
            </div>
            <div className='fv-row mb-8'>
              <input
                placeholder='Chasis Id'
                className='form-control bg-transparent mt-3'
                type='text'
                name=''
                value={chasisId}
                onChange={(e) => setChasisId(e.target.value)}
              />
            </div>
            <div className='fv-row mb-8'>
              <input
                placeholder='Board Number'
                className='form-control bg-transparent mt-3'
                type='text'
                name='boardNumber'
                value={boardNumber}
                onChange={(e) => setboardNumber(e.target.value)}
              />
            </div>

            <div className='fv-row mb-8'>
              <input
                placeholder='Location'
                className='form-control bg-transparent mt-3'
                type='date'
                name='date'
                value={dateOfManufauctring}
                onChange={(e) => setdateOfManufauctring(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <div className='mb-6 text-center'>
          <button type='button' className='btn btn-secondary btn-sm mx-2' onClick={closeModal}>
            Close
          </button>
          <button
            type='button'
            className='btn btn-sm mx-2 text-white'
            style={{background: '#493184'}}
            onClick={addMachinesHandler}
          >
            Save
          </button>
        </div>
      </Modal>

      <nav aria-label='Page navigation example'>
        <ul className='pagination'>
          <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
            <a className='page-link'  onClick={handlePreviousPage}>
              Previous
            </a>
          </li>
          {pageNumbers.map((pageNumber) => (
            <li className={`page-item ${page === pageNumber ? 'active' : ''}`} key={pageNumber}>
              <a className='page-link' onClick={() => setPage(pageNumber)}>
                {pageNumber}
              </a>
            </li>
          ))}
          <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
            <a className='page-link' onClick={handleNextPage}>
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export {Machines}
