/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Search} from '../../../_metronic/partials'
import Modal from 'react-bootstrap/Modal'

type Props = {
  className: string
}
// Your static data stored in an array
const membersData = [
  {
    id: 1,
    Id: '001',

    CouponName: 'Welcome Offer',
    Type: 'New User',
    Discount: 'Flat Rs.100 Off',
    ExpiryDate: '12/01/2023',
    Status: 'Inactive',
  },
  {
    id: 2,
    Id: '002',

    CouponName: 'HDFC Offer',
    Type: 'Bank Offer',
    Discount: 'Rs.200 Off on Mini. Order 799',
    ExpiryDate: '12/01/2023',
    Status: 'Active',
  },
  {
    id: 3,
    Id: '003',

    CouponName: 'EOSS Sale',
    Type: 'Others',
    Discount: 'Flat Rs.500 Off',
    ExpiryDate: '12/01/2023',
    Status: 'Active',
  },
  {
    id: 4,
    Id: '004',

    CouponName: 'Christmas Offer',
    Type: 'New User',
    Discount: 'Flat 20% Off',
    ExpiryDate: '12/01/2023',
    Status: 'Active',
  },
  {
    id: 5,
    Id: '005',

    CouponName: 'Welcome Offer',
    Type: 'New User',
    Discount: 'Flat Rs.100 Off',
    ExpiryDate: '12/01/2023',
    Status: 'Active',
  },
  {
    id: 6,
    Id: '006',

    CouponName: 'Welcome Offer',
    Type: 'New User',
    Discount: 'Flat Rs.100 Off',
    ExpiryDate: '12/01/2023',
    Status: 'Inactive',
  },
  {
    id: 7,
    Id: '007',

    CouponName: 'Welcome Offer',
    Type: 'New User',
    Discount: 'Flat Rs.100 Off',
    ExpiryDate: '12/01/2023',
    Status: 'Inactive',
  },
  {
    id: 8,
    Id: '008',

    CouponName: 'Welcome Offer',
    Type: 'New User',
    Discount: 'Flat Rs.100 Off',
    ExpiryDate: '12/01/2023',
    Status: 'Inactive',
  },
  {
    id: 9,
    Id: '009',

    CouponName: 'Welcome Offer',
    Type: 'New User',
    Discount: 'Flat Rs.100 Off',
    ExpiryDate: '12/01/2023',
    Status: 'Inactive',
  },
  {
    id: 10,
    Id: '010',

    CouponName: 'Welcome Offer',
    Type: 'New User',
    Discount: 'Flat Rs.100 Off',
    ExpiryDate: '12/01/2023',
    Status: 'Inactive',
  },
]

// Inside your Machines component
const Coupons: React.FC<Props> = ({className}) => {
  const [showModal, setShowModal] = useState(false)
  const [showDelete, setshowDelete] = useState(false)
  const addCouponHandler = () => {
    setShowModal(true) // Function to show the modal
  }

  const closeModal = () => {
    setShowModal(false) // Function to close the modal
  }
  const deleteMachineHandler = async (machineId: number) => {
    console.log('id', machineId)
    // try {
    //   const response = await axios.delete(`http://localhost:9000/api/deletemachine?id=${machineId}`)
    //   console.log('Delete machine', response)
    //   setMachines((prevMachines) => prevMachines.filter((machine) => machine.id !== machineId))
    //   setshowDelete(false)
    // } catch (error) {
    //   console.error(error)
    // }
  }
  const closeDelete = () => {
    setshowDelete(false)
  }
  const deleteMachine = (val: number) => {
    setshowDelete(true)
    // setmachineData(val)
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>{/* <Search /> */}</div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm mx-2'
            style={{background: '#493184'}}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
            onClick={addCouponHandler}
          >
            <KTIcon iconName='plus' className='fs-3 text-white' />
            <span className='text-white'>Add Coupon</span>
          </a>
          <a
            href='#'
            style={{background: '#493184'}}
            className='btn btn-sm mx-2'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='filter' className='fs-3 text-white' />
            <span className='text-white'>Filter</span>
          </a>
          <a
            href='#'
            className='btn btn-sm mx-2'
            style={{background: '#493184'}}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='arrows' className='fs-3 text-white' />
            <span className='text-white'>Export</span>
          </a>
        </div>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Sr. No.</th>
                <th className='min-w-150px'>Coupon Name</th>
                <th className='min-w-150px'>Type</th>
                <th className='min-w-150px'>Discount</th>
                <th className='min-w-150px'>Expiry Date</th>
                <th className='min-w-150px'>Status</th>
                <th className='min-w-140px'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {membersData.map((member) => (
                <tr key={member.id}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value='1'
                      />
                    </div>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.Id}
                    </span>
                  </td>

                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.CouponName}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.Type}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.Discount}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {member.ExpiryDate}
                    </span>
                  </td>

                  <td className=''>
                    {member.Status === 'Active' && (
                      <span className='badge badge-light-success'>Active</span>
                    )}
                    {member.Status === 'Inactive' && (
                      <span className='badge badge-light-danger'>Inactive</span>
                    )}
                  </td>

                  <td>
                    <div className='d-flex '>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </a>

                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        onClick={() => deleteMachine(member.id)}
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td>
                  <Modal show={showDelete} onHide={closeDelete} centered>
                    <Modal.Body>
                      <div className='d-flex flex-column text-center'>
                        <div>
                          <span>Are you sure you want to delete Coupon?</span>
                        </div>
                      </div>
                    </Modal.Body>
                    <div className='mb-6 text-center'>
                      <button
                        type='button'
                        className='btn btn-secondary btn-sm mx-2'
                        onClick={closeDelete}
                      >
                        Close
                      </button>
                      <button
                        type='button'
                        className='btn btn-sm mx-2 text-white btn-danger'
                        // onClick={() => deleteMachineHandler(machineData ?? -1)}
                      >
                        Delete
                      </button>
                    </div>
                  </Modal>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered>
        <h2 style={{textAlign: 'center', width: '100%'}} className='mt-4'>
          Add Coupon
        </h2>

        <Modal.Body>
          {/* Content of your modal goes here */}
          {/* For example, your vertically centered modal */}
          <div className='modal-dialog'>
            <div className='fv-row mb-8'>
              <h3>Coupon Type</h3>
              <div className='d-flex'>
                <input type='radio'></input>
                <span className='me-6 ms-2'>New User Coupon</span>
                <input type='radio'></input>
                <span className='me-6 ms-2'>Target Values</span>
                <input type='radio'></input>
                <span className='me-6 ms-2'>Bank Offer</span>
                <input type='radio'></input>
                <span className='ms-2'>Others</span>
              </div>
            </div>
            <div className='fv-row mb-8'>
              <h3>Value/Discount</h3>
              <input
                placeholder='Value/Discount'
                className='form-control bg-transparent mt-3'
                type='text'
                name='ID'
              />
            </div>
            <div className='fv-row mb-8'>
              <h3>Valid Date</h3>
              <input
                placeholder='Dec 21 2023'
                className='form-control bg-transparent mt-3'
                type='date'
                name='date'
              />
            </div>
            <div className='fv-row mb-8'>
              <h3>Upload Image</h3>
              <a
                href='#'
                className='btn btn-sm mx-2'
                style={{background: '#493184'}}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_invite_friends'
              >
                <KTIcon iconName='plus' className='fs-3 text-white' />
                <span className='text-white'>Add Image</span>
              </a>
            </div>
          </div>
        </Modal.Body>
        <div className='mb-6 text-center'>
          <button type='button' className='btn btn-secondary btn-sm mx-2' onClick={closeModal}>
            Close
          </button>
          <button
            type='button'
            className='btn btn-sm mx-2 text-white'
            style={{background: '#493184'}}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  )
}

export {Coupons}
