import {FC,useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  titleStyle?: React.CSSProperties;
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  titleStyle,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const [isHovered, setIsHovered] = useState(false);

  

  const menuTitleStyle: React.CSSProperties = {
    color: isActive || isHovered ? 'blue' : 'white',
  };
  return (
<div className='menu-item'>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
       
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
<span className='menu-title' style={menuTitleStyle}>
          {title}
        </span>
         
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
