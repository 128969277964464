import React from 'react'
import image3 from '../../../../assests/CaneBot.png'
const CaneBotImage = () => {
  return (
    <div
    className='card card-header border-0'   
  >
    <img 
        src={image3} className='' style={{height:"22rem", objectFit: "contain"}}></img>
  </div>
  )
}

export default CaneBotImage