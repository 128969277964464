import React, {useEffect, useState, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Search} from '../../../_metronic/partials'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import {format, parseISO} from 'date-fns'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import image1 from '../../../assests/Flavor-1.png'
import image2 from '../../../assests/Flavor-2.png'
import image3 from '../../../assests/Flavor-3.png'
import image4 from '../../../assests/Flavor-4.png'
import image5 from '../../../assests/Flavor-5.png'
import image6 from '../../../assests/Flavor-6.png'

interface GlassData {
  id: number
  orderId: string
  title: string
  imageSrc: any
  data: any
  available: boolean
  rate: number
  createdAt: string
  recipie: number
  sequence: number
}

type Props = {
  className: string
}

const Flavours: React.FC<Props> = ({className}) => {
  const [flavors, setFlavors] = useState<GlassData[]>([])
  const [showToggleModal, setShowToggleModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedFlavor, setSelectedFlavor] = useState<GlassData | null>(null)
  const [flavorName, setFlavorName] = useState<string>('');

  const [isInactive, setIsInactive] = useState(false)
  const [editedValues, setEditedValues] = useState<{
    sequence: number
    recipie: number
    title: string
    rate: number
  }>({
    sequence: 0,
    recipie: 0,
    title: '',
    rate: 0,
  })

  const [newFlavor, setNewFlavor] = useState<GlassData>({
    id: 0,
    orderId: '',
    title: '',
    imageSrc: '',
    data: null, // You might want to adjust this based on your requirements
    available: false,
    rate: 0,
    createdAt: '',
    recipie: 0,
    sequence: 0,
  })

  const handleNewFlavorChange = (fieldName: keyof GlassData, value: string | number | boolean) => {
    setNewFlavor((prevFlavor) => ({
      ...prevFlavor,
      [fieldName]: value,
    }))
  }

  const [showAddModal, setShowAddModal] = useState(false)

  const API_URL = process.env.REACT_APP_API_URL

  // Refs for input fields
  const sequenceInputRef = useRef<HTMLInputElement>(null)
  const recipieNoInputRef = useRef<HTMLInputElement>(null)
  const titleInputRef = useRef<HTMLInputElement>(null)
  const rateInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    fetchGlasses()
  }, [])

  const fetchGlasses = async () => {
    try {
      const response = await axios.get(`${API_URL}/get/flavour`, {})
      setFlavors(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const toggleStatus = (flavor: GlassData) => {
    setSelectedFlavor(flavor)
    setIsInactive(flavor.available === true)
    setShowToggleModal(true)
    setFlavorName(flavor.title);
  }

  const handleToggleModalClose = () => {
    setShowToggleModal(false)
  }

  const handleDeactivate = async () => {
    try {
      if (selectedFlavor) {
        const updatedFlavors = flavors.map((flavor) =>
          flavor.id === selectedFlavor.id
            ? {...flavor, available: flavor.available === true ? false : true}
            : flavor
        )

        setFlavors(updatedFlavors)

        await axios.put(`${API_URL}/flavours/${selectedFlavor.id}/status`, {
          available: updatedFlavors.find((flavor) => flavor.id === selectedFlavor.id)?.available,
        })

        console.log('Flavor updated successfully')
      }
    } catch (error) {
      console.log(error)
    }
    setShowToggleModal(false)
  }

  async function handleImageUpload(event: any, member: any) {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async function () {
        const base64String = typeof reader.result === 'string' ? reader.result.split(',')[1] : ''
        member.imageSrc = base64String

        try {
          // Make a POST request to save the image data
          await axios.put(`${API_URL}/flavours/${member.id}/image`, {
            imageSrc: base64String,
          })
          console.log('Image uploaded successfully')
          toast.success('Image uploaded successfully')
          fetchGlasses()
        } catch (error) {
          console.error('Error occurred while uploading image:', error)
        }
      }

      reader.onerror = function (error) {
        console.error('Error occurred while reading the file:', error)
      }
    }
  }

  const handleEdit = (fieldName: keyof typeof editedValues, value: string | number) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: typeof value === 'number' ? parseFloat(value.toString()) : value, // Convert to number if the field is rate or recipe
    }))
  }

  const handleSave = async (id: number) => {
    try {
      const updatedFlavor = {
        ...flavors.find((flavor) => flavor.id === id),
        ...editedValues,
      }

      console.log('updatedFlavor-->', updatedFlavor)

      // await axios.put(`${API_URL}/flavours/${id}`, updatedFlavor);
      // Refresh the flavors data after updating
      fetchGlasses()
      console.log('Flavor updated successfully')
    } catch (error) {
      console.error('Error occurred while updating flavor:', error)
    }
  }

  const handleEditModal = (flavor: GlassData) => {
    setSelectedFlavor(flavor)
    setEditedValues({
      sequence: flavor.sequence,
      recipie: flavor.recipie,
      title: flavor.title,
      rate: flavor.rate,
    })
    setShowEditModal(true)
  }

  const handleSaveModal = async () => {
    try {
      if (selectedFlavor) {
        const updatedFlavor = {
          ...selectedFlavor,
          ...editedValues,
        }

        // Update the flavor data
        await axios.put(`${API_URL}/flavours/${selectedFlavor.id}`, updatedFlavor)

        // Refresh the flavors data after updating
        fetchGlasses()

        console.log('Flavor updated successfully')
      }
    } catch (error) {
      console.error('Error occurred while updating flavor:', error)
    }

    // Close the modal
    setShowEditModal(false)
  }

  const handleAddModalClose = () => {
    setShowAddModal(false)
  }

  const handleAddModalSave = async () => {
    try {
      await axios.post(`${API_URL}/flavour`, newFlavor)
      fetchGlasses()
      console.log('New flavor added successfully')
    } catch (error) {
      console.error('Error occurred while adding new flavor:', error)
    }

    // Close the modal
    setShowAddModal(false)
  }

  const handleNewImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = reader.result?.toString().split(',')[1] || ''
        setNewFlavor((prevFlavor) => ({
          ...prevFlavor,
          imageSrc: base64String,
        }))
      }
      reader.onerror = (error) => {
        console.error('Error occurred while reading the file:', error)
      }
    }
  }

  return (
    <div className={`card ${className}`}>
      <ToastContainer />
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>{/* <Search /> */}</div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {/* <a
            href='/'
            style={{background: '#493184'}}
            className='btn btn-sm mx-2'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='filter' className='fs-3 text-white' />
            <span className='text-white'>Filter</span>
          </a> */}
          {/* <a
            href='/'
            className='btn btn-sm mx-2'
            style={{background: '#493184'}}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='arrows' className='fs-3 text-white' />
            <span className='text-white'>Export</span>
          </a> */}
          <button
            className='btn btn-sm mx-2'
            style={{background: '#493184'}}
            onClick={() => setShowAddModal(true)}
          >
            <KTIcon iconName='plus' className='fs-3 text-white' />
            <span className='text-white'>Add Flavor</span>
          </button>
        </div>
      </div>
      <div className='card-body py-1'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
            <thead>
              <tr className='fw-bold text-muted'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th> */}
                <th className='min-w-150px ms-4'>ID</th>
                <th className='min-w-150px'>Sequence</th>
                <th className='min-w-150px'>Recipe No.</th>
                <th className='min-w-150px'>Flavours</th>
                <th className='min-w-140px'>Rate</th>
                <th className='min-w-150px'>Date</th>
                <th className='min-w-150px'>Image</th>
                <th className='min-w-150px'>Upload Image</th>
                <th className='min-w-150px ms-4'>Status</th>
                <th className='min-w-150px'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {flavors
                .slice()
                .sort((a, b) => a.id - b.id)
                .map((member) => (
                  <tr key={member.id}>
                    {/* <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td> */}
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7 ms-2'>
                        {member.id}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7 ms-4'>
                        {member.sequence}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7 ms-4'>
                        {member.recipie}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {member.title}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {member.rate}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {format(member.createdAt, 'MMM dd, yyyy hh:mm:ss a')}
                      </span>
                    </td>
                    <td className=''>
                      {member.imageSrc && (
                        <img
                          src={`data:image/png;base64,${member.imageSrc}`}
                          alt={member.title}
                          style={{width: '50px', height: '50px'}}
                        />
                      )}
                    </td>
                    <td>
                      <div>
                        <input
                          type='file'
                          onChange={(e) => handleImageUpload(e, member)}
                          accept='image/*'
                        />
                      </div>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold  d-block fs-7 me-4'>
                        <label className='switch'>
                          <input
                            type='checkbox'
                            checked={member.available === true}
                            onChange={() => toggleStatus(member)}
                          />
                          <span className='slider round'></span>
                        </label>
                      </span>
                    </td>
                    <td>
                      <button className='btn btn-primary' onClick={() => handleEditModal(member)}>
                        Edit
                      </button>
                    </td>
                  </tr>
                ))} 
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showToggleModal} onHide={handleToggleModalClose} centered>
        <Modal.Body>
          <div className='d-flex flex-column text-center'>
            <div>
              <span>Are you sure you want to {isInactive ? 'inactive' : 'active'} {flavorName}?</span>
            </div>
          </div>
        </Modal.Body>
        <div className='mb-6 text-center'>
          <button
            type='button'
            className='btn btn-secondary btn-sm mx-2'
            onClick={handleToggleModalClose}
          >
            Close
          </button>
          <button
            type='button'
            className='btn btn-sm mx-2 text-white btn-danger'
            onClick={handleDeactivate}
          >
            {isInactive ? 'Inactive' : 'Active'}
          </button>
        </div>
      </Modal>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Flavor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            <label htmlFor='sequence' className='form-label'>
              Sequence
            </label>
            <input
              type='number'
              className='form-control'
              id='sequence'
              value={editedValues.sequence}
              onChange={(e) => handleEdit('sequence', e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='recipie' className='form-label'>
              Recipe No.
            </label>
            <input
              type='number'
              className='form-control'
              id='recipie'
              value={editedValues.recipie}
              onChange={(e) => handleEdit('recipie', e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='title' className='form-label'>
              Title
            </label>
            <input
              type='text'
              className='form-control'
              id='title'
              value={editedValues.title}
              onChange={(e) => handleEdit('title', e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='rate' className='form-label'>
              Rate
            </label>
            <input
              type='number'
              className='form-control'
              id='rate'
              value={editedValues.rate}
              onChange={(e) => handleEdit('rate', e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => setShowEditModal(false)}
          >
            Close
          </button>
          <button type='button' className='btn btn-primary' onClick={handleSaveModal}>
            Save changes
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add Flavor Modal */}
      <Modal show={showAddModal} onHide={handleAddModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Flavor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            <label htmlFor='newId' className='form-label'>
              ID
            </label>
            <input
              type='number'
              className='form-control'
              id='newId'
              value={newFlavor.id}
              onChange={(e) => handleNewFlavorChange('id', e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='newSequence' className='form-label'>
              Sequence
            </label>
            <input
              type='number'
              className='form-control'
              id='newSequence'
              value={newFlavor.sequence}
              onChange={(e) => handleNewFlavorChange('sequence', e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='newRecipie' className='form-label'>
              Recipe No.
            </label>
            <input
              type='number'
              className='form-control'
              id='newRecipie'
              value={newFlavor.recipie}
              onChange={(e) => handleNewFlavorChange('recipie', e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='newTitle' className='form-label'>
              Title
            </label>
            <input
              type='text'
              className='form-control'
              id='newTitle'
              value={newFlavor.title}
              onChange={(e) => handleNewFlavorChange('title', e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='newRate' className='form-label'>
              Rate
            </label>
            <input
              type='number'
              className='form-control'
              id='newRate'
              value={newFlavor.rate}
              onChange={(e) => handleNewFlavorChange('rate', e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='newImageSrc' className='form-label'>
              Upload Image
            </label>
            <input
              type='file'
              className='form-control'
              id='newImageSrc'
              onChange={(e) => handleNewImageUpload(e)}
              accept='image/*'
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='newAvailable' className='form-label'>
              Available
            </label>
            <select
              className='form-control'
              id='newAvailable'
              value={newFlavor.available ? 'true' : 'false'}
              onChange={(e) => handleNewFlavorChange('available', e.target.value === 'true')}
            >
              <option value='true'>True</option>
              <option value='false'>False</option>
            </select>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button type='button' className='btn btn-secondary' onClick={handleAddModalClose}>
            Close
          </button>
          <button type='button' className='btn btn-primary' onClick={handleAddModalSave}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {Flavours}
