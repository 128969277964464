// SidebarMenuMain.tsx

import React from 'react';
import { useIntl } from 'react-intl';
import { SidebarMenuItem } from './SidebarMenuItem';

const whiteTextStyle = { color: '' };

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='abstract-48'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
        
      />
      <SidebarMenuItem to='/orders' icon='abstract-43' title='Orders' fontIcon='bi-layers'/>
      {/* <SidebarMenuItem to='/glass' icon='abstract-32' title='Glass' fontIcon='bi-layers'/> */}
      <SidebarMenuItem to='/flavours' icon='abstract-32' title='Flavors' fontIcon='bi-layers'/>
      <SidebarMenuItem
        to='/machines'
        icon='abstract-39'
        title='Machines'
        fontIcon='bi-app-indicator'
        titleStyle={whiteTextStyle}
      />
      {/* <SidebarMenuItem to='/users' icon='user' title='Users' fontIcon='bi-app-indicator'/>
      <SidebarMenuItem to='/coupons' icon='tag' title='Coupon' fontIcon='bi-app-indicator' /> */}
    </>
  );
};

export { SidebarMenuMain };
