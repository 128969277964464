import React from 'react'
import image1 from '../../../../assests/Header.png'

const HeaderImage = () => {
  return (
    <div className=''>
      <img src={image1} alt='' style={{width:"100%"}}></img>
    </div>
  )
}

export default HeaderImage
