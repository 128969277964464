import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Flavours } from './Flavours'

const FlavoursWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Flavors</PageTitle>
      <Flavours className='' />
    </>
  )
}

export default FlavoursWrapper
