/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Search} from '../../../_metronic/partials'
import image1 from '../../../assests/Rating1.png'
import image2 from '../../../assests/Rating2.png'
import image3 from '../../../assests/Rating3.png'
import axios from 'axios'
import {Icon} from '@iconify/react'
import {format} from 'date-fns'

interface Order {
  id: number
  orderNo: string
  masterOrderNo:string
  quantity: number
  orderStatus: string
  paymentStatus: string
  machineId: string
  division: string
  location: string
  createdAt:Date
  rating: number
  count: number
  discountedValue:number
  orderValue:number
  machine: {
    id: number
    division: string
    location: string
    machineStatus: string
    mode: string
    boardNumber: string
    dateOfManufacturing: Date
    chasisID: string
    Discount: number
    startTime: Date
    lastRefillTime: Date
    isDeactive: boolean
    createdAt: Date
    updatedAt: Date
  }
}

interface Glass {
  id: number
  orderNo:string
  orderId: string
  updatedAt: Date
  createdAt: Date
  flavour: string
  glassStatus: string
  glasses: string
  masterOrderNo:string
}
interface ApiResponse {
  data: Order[]
  data2:Glass[]
  totalCount: number
}
const Orders: React.FC = () => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null)
  const [orders, setOrders] = useState<Order[]>([])
  const [glasses, setGlasses] = useState<Glass[]>([]);
  const [query, setEnterSearch] = useState<string>('')
  const [sortColumn, setSortColumn] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [totalPages, setTotalPages] = useState(1)

  // console.log('orders---p--', orders)
  let token = ''
  const tokenItem = localStorage.getItem('kt-auth-react-v')
  console.log(tokenItem, 'tokenItem---------------')
  if (tokenItem) {
    const {api_token} = JSON.parse(tokenItem)

    if (typeof api_token === 'string') {
      token = api_token

      console.log('token=================', token)
      // You can proceed using 'token' here
    } else {
      // Handle the case where api_token is null or not a string
    }
  } else {
    // Handle the case where tokenItem is null
  }
  const API_URL = process.env.REACT_APP_API_URL
  const handleSort = (column: any) => {
    if (sortColumn === column) {
      // If the column is already sorted, toggle the direction
      setSortDirection((prevDir) => (prevDir === 'asc' ? 'desc' : 'asc'))
    } else {
      // If sorting a new column, set the column and default direction to ascending
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<ApiResponse>(`${API_URL}/daywise`, {
          params: {
            page: page,
            limit: limit,
            search: query,
            sortBy: sortColumn,
            sortDir: sortDirection,
          },
        })

        const {data, totalCount} = response.data

        // Map over the data to include machine information
        const ordersWithMachineData: Order[] = data.map((order: any) => ({
          ...order,
          machine: order.machine,
        }))

        setOrders(ordersWithMachineData)
        setTotalPages(Math.ceil(totalCount / limit))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [query, page, sortColumn, sortDirection])

  useEffect(() => {
    const fetchGlasses = async () => {
      try {
    //     const response = await axios.get<ApiResponse>(`${API_URL}/get/individual`);
    //     const { data2 } = response.data; // Extract data array from response
    // console.log("res------------->",response.data)
    //     // Assuming data is an array of Glass objects, set the state accordingly
    //     setGlasses(data2 || []); // Ensure data2 is not undefined
    const response=await axios.get(`${API_URL}/get/individual`)
    console.log("dataglass",response.data)
    setGlasses(response.data)
      } catch (err) {
        console.log(err);
      }
    };
fetchGlasses()
  }, []); //

  // console.log('ordersssss', orders)

  const handleExpandRow = (id: number) => {
   
    setExpandedRow((prevExpandedRow) => (prevExpandedRow === id ? null : id))
  }
  const filteredOrders = orders?.filter((order) => String(order.machineId).includes(query))




  const handlePreviousPage = () => {
    setPage((page) => Math.max(page - 1, 1))
  }

  const handleNextPage = () => {
    setPage((page) => page + 1)
  }
  const pageRange = 2 // Adjust this value as needed

  // Calculate the start and end page numbers to display based on the current page
  const startPage = Math.max(1, page - pageRange)
  const endPage = Math.min(totalPages, page + pageRange)

  // Create an array of page numbers to display
  const pageNumbers = Array.from({length: endPage - startPage + 1}, (_, index) => startPage + index)

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>
          {/* <Search setEnterSearch={setEnterSearch} /> */}
        </div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {/* <a
            href='#'
            style={{background: '#493184'}}
            className='btn btn-sm mx-2'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='filter' className='fs-3 text-white' />
            <span className='text-white'>Filter</span>
          </a> */}
          {/* <a
            href='#'
            className='btn btn-sm mx-2'
            style={{background: '#493184'}}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='arrows' className='fs-3 text-white' />
            <span className='text-white'>Export</span>
          </a> */}
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold  text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                </th>

                <th className='min-w-120px' onClick={() => handleSort('orderNo')}>
                  <div className='d-flex align-items-center'>
                    {sortColumn === 'orderNo' && (
                      <Icon
                        icon={sortDirection === 'asc' ? 'carbon:arrow-up' : 'carbon:arrow-down'}
                      />
                    )}
                    <span className='me-1'>Order No</span>
                  </div>
                </th>

                <th className='min-w-120px' onClick={() => handleSort('quantity')}>
                  <div className='d-flex align-items-center'>
                    {sortColumn === 'quantity' && (
                      <Icon
                        icon={sortDirection === 'asc' ? 'carbon:arrow-up' : 'carbon:arrow-down'}
                      />
                    )}
                    <span className='me-1'>Quantity</span>
                  </div>
                </th>
                <th className='min-w-140px'>Order Status</th>
                <th className='min-w-140px'>Payment Status</th>
                <th className='min-w-140px'>Time</th>

                <th className='min-w-140px'>Machine Id</th>
                <th className='min-w-120px'>Order Value</th>
                <th className='min-w-120px'>Discounted Value</th>
                {/* <th className='min-w-120px' onClick={() => handleSort('division')}>
                  <div className='d-flex align-items-center'>
                    {sortColumn === 'division' && (
                      <Icon
                        icon={sortDirection === 'asc' ? 'carbon:arrow-up' : 'carbon:arrow-down'}
                      />
                    )} 
                    <span className='me-1'>Division</span>
                  </div>
                </th> */}
                <th className='min-w-120px' onClick={() => handleSort('location')}>
                  <div className='d-flex align-items-center'>
                    {sortColumn === 'location' && (
                      <Icon
                        icon={sortDirection === 'asc' ? 'carbon:arrow-up' : 'carbon:arrow-down'}
                      />
                    )}
                    <span className='me-1'>Location</span>
                  </div>
                </th>
               
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => (
                <>
                  <tr key={order.id}>
                    <td>
                      <div className='accordion' id={`accordion_${order.id}`} style={{padding: 0}}>
                        <h2 className='accordion-header' id={`heading_${order.id}`}>
                          <button
                            className={`accordion-button ${
                              expandedRow === order.id ? 'active' : ''
                            }`}
                            type='button'
                            style={{padding: 0}}
                            data-bs-toggle='collapse'
                            data-bs-target={`#collapse_${order.id}`}
                            aria-expanded={expandedRow === order.id}
                            aria-controls={`collapse_${order.id}`}
                            onClick={() => handleExpandRow(order.id)}
                          ></button>
                        </h2>
                      </div>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {order?.masterOrderNo}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {order.quantity > 1
                          ? `${order.quantity} flavours`
                          : `${order.quantity} flavour`}
                      </span>
                    </td>
                    <td>
                      {order.orderStatus === 'Success' && (
                        <span className='badge badge-light-success'>{order.orderStatus}</span>
                      )}
                      {order.orderStatus === 'Failure' && (
                        <span className='badge badge-light-danger'>{order.orderStatus}</span>
                      )}
                      {order.orderStatus === 'Initiated' && (
                        <span className='badge badge-light-warning'>{order.orderStatus}</span>
                      )}
                    </td>
                    <td>
                      {order.paymentStatus === 'Initiated' && (
                        <span className='badge badge-light-warning '>{order.paymentStatus}</span>
                      )}
                      {order.paymentStatus === 'Success' && (
                        <span className='badge badge-light-success'>{order.paymentStatus}</span>
                      )}
                      {order.paymentStatus === 'Failure' && (
                        <span className='badge badge-light-danger'>{order.paymentStatus}</span>
                      )}
                    </td>
                    <td>
                    <span className='text-muted fw-semibold d-block fs-7'
                   >
                    {order?.createdAt ? format(new Date(order.createdAt), 'MMM dd, yyyy hh:mm:ss a') : 'N/A'}
                      </span>
                                     


                                      </td>
                    <td className=''>
                      <span className='text-muted fw-semibold text-muted d-block fs-7 '>
                        {order.machineId}
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {order.orderValue}
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {order.discountedValue}
                      </span>
                    </td>
                    {/* <td className=''>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {order.division}
                      </span>
                    </td> */}
                    <td className=''>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {order.location}
                      </span>
                    </td>
                    {/* <td>
                      {order.rating === 1 && <img src={image1} alt='success'></img>}
                      {order.rating === 2 && <img src={image2} alt='process'></img>}
                      {order.rating === 3 && <img src={image3} alt='process'></img>}
                    </td> */}
                  </tr>
                  {expandedRow === order.id && (
                    <tr>
                      <td colSpan={10}>
                        {' '}
                        {/* Adjust colspan based on the number of columns */}
                        <div className='accordion-body'>
                          <div className='table-responsive'>
                            <table className='table table-striped table-row-dashed'>
                              <thead>
                                <tr>
                                  <th style={{minWidth: '120px'}}>Id No.</th>
                                  <th style={{minWidth: '150px'}}>Time</th>
                                  <th style={{minWidth: '140px'}}>Flavours</th>
                                  <th style={{minWidth: '140px'}}>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {glasses
                                  .filter((glass) =>glass && glass.masterOrderNo === order.masterOrderNo)
                                  .map((glass) => (
                                    <tr key={glass.id}>
                                      <td>{glass.orderNo}</td>
                                      <td>
                                      {glass?.createdAt ? format(new Date(glass.createdAt), 'MMM dd, yyyy hh:mm:ss a') : 'N/A'}


                                      </td>
                                      <td>{glass.flavour}</td>
                                      <td>
                                        {glass.glassStatus}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>

        <nav aria-label='Page navigation example'>
          <ul className='pagination'>
            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
              <a className='page-link' href='#' onClick={handlePreviousPage}>
                Previous
              </a>
            </li>
            {/* Loop through the displayed page numbers */}
            {pageNumbers.map((pageNumber) => (
              <li className={`page-item ${page === pageNumber ? 'active' : ''}`} key={pageNumber}>
                <a className='page-link' onClick={() => setPage(pageNumber)}>
                  {pageNumber}
                </a>
              </li>
            ))}
            <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
              <a className='page-link' onClick={handleNextPage}>
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}
export {Orders}
