import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Coupons } from './Coupons'

const CouponsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Coupons</PageTitle>
      <Coupons className='' />
    </>
  )
}

export default CouponsWrapper
