import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Glass } from './glass'

const GlassWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Glasses</PageTitle>
      <Glass className='' />
    </>
  )
}

export default GlassWrapper
