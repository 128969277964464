// Import necessary modules
import React, {useState} from 'react'
import MapBox from './Map'
import BarChart1 from './BarChart'

// Define the SecondRow functional component
function SecondRow() {
  // Define state for selected location, initial value is null or string
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null)

  // Update the selected location when a marker is clicked
  const handleDataUpdate = (location: string) => {
    setSelectedLocation(location)
  }

  // Return JSX elements
  return (
    <div>
      <div className='container mt-8 '>
        <div className='row'>
          <div className='col-md-6 mb-3 '>
            {/* <MapBox onDataUpdate={handleDataUpdate} /> */}
          </div>
          <div className='col-md-6 mb-3 '>
            {/* <BarChart1 selectedLocation={selectedLocation ?? undefined} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

// Export the SecondRow component
export default SecondRow
